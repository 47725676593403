import MicroModal from "micromodal";

const map = {
  cfg: {
    name: 'map'
  },

  createMapHead() {
    const jsCDN = 'https://api.tiles.mapbox.com/mapbox-gl-js/v2.6.1/mapbox-gl.js';
    const cssCDN = 'https://api.tiles.mapbox.com/mapbox-gl-js/v2.6.1/mapbox-gl.css';

    let scriptTag = document.createElement("script");
    var linkTag = document.createElement("link");

    scriptTag.type = "text/javascript";
    linkTag.rel = "stylesheet";

    scriptTag.src = jsCDN;
    linkTag.href = cssCDN;

    document.getElementsByTagName("head")[0].appendChild(scriptTag);
    document.getElementsByTagName("head")[0].appendChild(linkTag);

    return false;
  },

  loadMapbox() {

    mapboxgl.accessToken =
    'pk.eyJ1Ijoic3Rhcmtjb2RlcyIsImEiOiJjam9wemc4ZzEwMW12M2twMnZ6ODM3dThpIn0.D8t6MpHi-9gLGA7hFyzYuQ';
    var bounds = new mapboxgl.LngLatBounds();
    var twigJSON = document.getElementById('map-json').getAttribute('data-map-json');
    twigJSON = eval(twigJSON);

    console.log(twigJSON);

    const geojson = {
        'type': 'FeatureCollection',
        'features': twigJSON
        
    };

    geojson.features.forEach(function (feature) {
        bounds.extend(feature.geometry.coordinates);
    });

    const map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/starkcodes/cjqy1corm10aq2snvf2rzllmo',
        center: [8.2125071, 51.1543811],
        maxZoom: 10,
        minZoom: 3,
        zoom: 4, 
        // maxBounds:  [[2.38865807458, 45.3024876979],
        //             [17.0169958839,57]]
    });

    // add markers to map
    for (const feature of geojson.features) {
        // create a HTML element for each feature
        const el = document.createElement('div');
        el.className = 'marker';

        var markerWidth = 12;
        var markerHeight = 12;

        el.style.width = markerWidth + 'px';
        el.style.height = markerHeight + 'px';

        // make a marker for each feature and add it to the map
        let marker = new mapboxgl.Marker(el)
            .setLngLat(feature.geometry.coordinates)
            // .setPopup(
            //     new mapboxgl.Popup({
            //         offset: 10,
            //         closeOnClick: false
            //     }) // add popups
            //     .setHTML(
            //         `<h3 data-mimimi-open="${feature.properties.modal}">${feature.properties.title}</h3>`
            //     )
            // )
            .addTo(map)
            // .togglePopup();

         // Add Click Event to Marker
         marker.getElement().addEventListener('click', () => {
          MicroModal.show(feature.properties.modal);
        });
    }

    map.fitBounds(bounds, {
        padding: {
            top: 400,
            bottom: 120,
            left: 40,
            right: 40
        }
    });

    // map.setMaxBounds(bounds);

    // map.fitBounds([5.98865807458, 49.3024876979, 15.0169958839, 54.983104153], {
    //     padding: {
    //         top: 180,
    //         bottom: 40,
    //         left: 40,
    //         right: 40
    //     }
    // });

    map.scrollZoom.disable();
    // map.doubleClickZoom.disable(); 
    // map.touchZoomRotate.enable();
  },

  init() {
    window.addEventListener('load', () => {
        const buttons = [...document.querySelectorAll('[data-privacy-button]')];
        const container = document.querySelector('.m-privacy-map');
        let tagsVisible;
    
        if (buttons) {
    
            tagsVisible = false;
    
            buttons.forEach((button) => {
    
                button.onclick = () => {
                    container.classList.add('has-privacy-accepted');
    
                    if (!tagsVisible) {
                        this.createMapHead();
                        tagsVisible = true;
                    }

                    setTimeout(() =>{
                        this.loadMapbox();
                    },1000)
                };
            });
        }
    })
  }
}

export default map;