/**
 * imageGallery (Photoswipe
 */

// Dependencies
import PhotoSwipeLightbox from '../../../../node_modules/photoswipe/dist/photoswipe-lightbox.esm';
import PhotoSwipe from '../../../../node_modules/photoswipe/dist/photoswipe.esm.js';
/* eslint-disable */
const imageGallery = {
  init() {
    const galleries = document.querySelectorAll('.js-imageGallery');
    if (galleries) {
      galleries.forEach(gallery => {
        const lightbox = new PhotoSwipeLightbox({
          gallery: '.js-imageGallery',
          children: 'a',
          pswpModule: PhotoSwipe
        });
        lightbox.init();
      });
    }
  },
};
/* eslint-enable */
export default imageGallery;
