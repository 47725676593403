/**
 * microModal
 */
import MicroModal from 'micromodal'

const microModal = {
  init() {
    window.addEventListener('load', () => {
      MicroModal.init({
        openTrigger: 'data-mimimi-open',
        onShow: modal => console.info(`${modal.id} is shown`), // [1]
        
      })
      window.microModal = MicroModal;
    })
  }
}

export default microModal
