/**
 * Page Transitions
 */

// Dependencies
// import Velocity from 'velocity-animate';
import goToUrl from '../lib/_goToUrl';

// Vars
const siteURL = `${window.location.protocol}//${window.location.host}`;
const triggers = [
  ...document.querySelectorAll(`a[href^="${siteURL}"]:not([href*="#"]):not(.no-transition)`),
];

triggers.forEach((trigger) => {
  // Click Event
  let dragged = 0;
  trigger.addEventListener(
    'mousedown',
    function () {
      dragged = 0;
    },
    false,
  );
  trigger.addEventListener(
    'mousemove',
    function () {
      dragged = 1;
    },
    false,
  );
  trigger.addEventListener(
    'mouseup',
    (event) => {
      // eslint-disable-line
      if (dragged === 0) {
        if (!(event.ctrlKey || event.metaKey || event.which === 2)) {
          const newLocation = trigger.getAttribute('href');
          event.preventDefault();

          // Set Transition Class
          document.body.classList.add('js-pageTransition');
          // Velocity(document.body, 'scroll', { duration: 250 }, 'easeOutCubic');

          // Go to New Page
          setTimeout(function () {
            goToUrl(newLocation);
          }, 50);
        }
      }
    },
    false,
  );
});

window.addEventListener('pageshow', function () {
  // check if document is loaded from cache (works only live)
  // if (event.persisted) {
  document.body.classList.remove('js-pageTransition');
  // }
});
