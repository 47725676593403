/**
 * modules
 */

import input from '../../../templates/atoms/input/_script'
import textarea from '../../../templates/atoms/textarea/_script'
import accordion from '../../../templates/organisms/accordion/_script'
import pageHeader from '../../../templates/organisms/pageHeader/_script'
// import counter from '../../../templates/organisms/counter/_script'
import imageSlider from '../../../templates/organisms/imageSlider/_script'
import imageGallery from '../../../templates/organisms/imageGallery/_script'
import cardSlider from '../../../templates/organisms/cardSlider/_script'
// import newsSlider from '../../../templates/organisms/newsSlider/_script'
// import testimonialSlider from '../../../templates/organisms/testimonialSlider/_script'
// import houseSlider from '../../../templates/organisms/houseSlider/_script'
import pageCover from '../../../templates/organisms/pageCover/_script'
import infoBox from '../../../templates/molecules/infoBox/_script'
import navExtended from '../../../templates/molecules/navExtended/_script'
import references from '../../../templates/organisms/references/_script'
import toggle from '../../../templates/molecules/toggle/_script'
import overlay from '../../../templates/molecules/overlay/_script'
import pageSlider from '../../../templates/organisms/pageSlider/_script'
import contactForm from '../../../templates/organisms/contactForm/_script'

// import partner from '../../../templates/organisms/partner/_script'
// import contactForm from     '../../../templates/_organisms/contactForm/_script';

// Modules Array
const modulesArray = [
  // preloader,
  input,
  textarea,
  imageSlider,
  imageGallery,
  accordion,
  navExtended,
  // counter,
  pageHeader,
  infoBox,
  imageSlider,
  references,
  cardSlider,
  pageSlider,
  contactForm,
  // newsSlider,
  // houseSlider,
  pageCover,
  // testimonialSlider,
  toggle,
  overlay
  // partner
]

const modules = {
  // Modules
  Modules() {
    if (modulesArray.length > 0 && modulesArray !== undefined) {
      modulesArray.forEach((module) => {
        module.init()
      })
    }
  },

  init() {
    this.Modules()
  }
}

export default modules
